.ssys-deep-dive-col {

  .header {
    text-align: center;
    border: 1px solid var(--sidebar);
    background: var(--sidebar);
    color: #fff;
    font-size: 1.75rem;
    padding: 1rem;
  }

  .total {
    border: 1px solid var(--border);
    font-size: 1.75rem;
    padding: 1rem;
    margin-top: .5rem;
    text-align: center;
  }

  .comparison {
    border: 1px solid var(--border);
    white-space: nowrap;

    &.negative {
      background-color: var(--error);
      color: #fff;
    }

    &.positive {
      background-color: var(--primary);
      color: #fff;
    }
  }

  .compare {
    margin-top: .5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: .5rem;

    .label {
      text-align: left;
      margin: .25rem .5rem;
      font-size: 1.25rem;
    }

    .value {
      margin: .5rem 1rem .75rem;
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .aw-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    margin-top: 2rem;

    & > * {
      border: 1px solid var(--border);
      padding: .25rem 0;
      text-align: center;
      font-weight: bold;
    }

    .head {
      background: var(--sidebar);
      border-color: var(--sidebar);
      color: #fff;
    }
  }
}