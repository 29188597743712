.cockpit-container {
  background-color: #000;

  margin: -1.5rem;
  min-height: calc(100% + 3rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cockpit {
  display: flex;
  width: 100%;
  padding: 4rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & > * {
    flex: 1;
  }

  .center {
    color: #fff;
    position: relative;
    display: flex;

    .location {
      flex: 1;
      font-size: 1.25rem;
    }

    .load {
      flex: 2;
      text-align: center;
      font-size: 1.5rem;
      padding-top: 2rem;

      .load-value {
        font-size: 3.5rem;
      }
    }

    .timeframe {
      flex: 1;
      text-align: right;
      font-size: 1.25rem;
    }

    .load-chart {
      color: #000;
      position: absolute;
      width: 75%;
      bottom: -2rem;
      left: 50%;
      transform: translateX(-50%);

      @media only screen and (min-width: 1200px) {
        bottom: 0;
      }
    }
  }
}