.ssys-layout-wrapper {
  height: 100vh;
  margin: 0 auto;
}

.ssys-layout-header {
  display: flex;
  align-items: center;
  overflow: hidden;

  img {
    cursor: pointer;
  }
}

.ssys-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3);
}

.ssys-layout-main {
  background-size: cover;
}

.ssys-layout-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ssys-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  span {
    font-size: 1.5rem;
    display: inline-block;
  }

  img {
    height: 1rem;
    margin-top: .5rem;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;

    img {
      height: 1.5rem;
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}