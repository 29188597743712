.tachometer {
  margin: auto;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  border-radius: 100%;
  display: block;
  position: relative;

  background: rgba(255, 255, 255, 0.1);
  border: 3px solid #fff;
  color: #fff;

  overflow: hidden;

  .label {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  .total {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
  }

  .goal {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 1.25rem;
  }

  .ii {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .ii div {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
  }

  .ii div b {
    position: absolute;
    display: block;
    left: 50%;
    width: 5px;
    height: 20px;
    background: #FFF;
    margin: 0 -5px 0;

  }

  .ii div:nth-child(2n+1) b {
    width: 10px;
    height: 35px;
    margin: 0 -5px 0;
  }

  .ii div:nth-child(1) {
    transform: rotate(240deg);
  }

  .ii div:nth-child(2) {
    transform: rotate(255deg);
  }

  .ii div:nth-child(3) {
    transform: rotate(270deg);
  }

  .ii div:nth-child(4) {
    transform: rotate(285deg);
  }

  .ii div:nth-child(5) {
    transform: rotate(300deg);
  }

  .ii div:nth-child(6) {
    transform: rotate(315deg);
  }

  .ii div:nth-child(7) {
    transform: rotate(330deg);
  }

  .ii div:nth-child(8) {
    transform: rotate(345deg);
  }

  .ii div:nth-child(9) {
    transform: rotate(0deg); /*---*/
  }

  .ii div:nth-child(10) {
    transform: rotate(15deg);
  }

  .ii div:nth-child(11) {
    transform: rotate(30deg);
  }

  .ii div:nth-child(12) {
    transform: rotate(45deg);
  }

  .ii div:nth-child(13) {
    transform: rotate(60deg);
  }

  .ii div:nth-child(14) {
    transform: rotate(75deg);
  }

  .ii div:nth-child(15) {
    transform: rotate(90deg);
  }

  .ii div:nth-child(16) {
    transform: rotate(105deg);
  }

  .ii div:nth-child(17) {
    transform: rotate(120deg);
  }

  [class^="num_"] {
    color: #FFF;
    display: block;
    position: absolute;
    width: 10px;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;

  }

  .num_1 {
    transform: rotate(120deg);
    top: 32px;
  }

  .num_2 {
    transform: rotate(90deg);
    top: 30px;
    left: 2px
  }

  .num_3 {
    transform: rotate(60deg);
    top: 27px;
  }

  .num_4 {
    transform: rotate(30deg);
    top: 32px;
  }

  .num_5 {
    transform: rotate(0deg);
    top: 40px;
    left: -4px
  }

  .num_6 {
    transform: rotate(330deg);
    top: 40px;
    left: 0
  }

  .num_7 {
    transform: rotate(300deg);
    top: 52px;
    left: -7px;
  }

  .num_8 {
    transform: rotate(270deg);
    top: 54px;
  }

  .num_9 {
    transform: rotate(240deg);
    top: 50px;
    left: 10px
  }

  .line {
    background: #F14134;
    background-image: linear-gradient(to bottom, #F14134, #343536);
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 0;
    transform-origin: 50% 100%;
    margin: calc(-50% + 15px) -8px 0;
    padding: calc(50% - 15px) 8px 0;
    z-index: 2;
    border-radius: 50% 50% 0 0;
    transform: rotate(0);
    opacity: .75;
  }

  .pin {
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -25px;
    background-color: #343536;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to bottom, #F14134, #343536);
    z-index: 4;
  }

  .inner {
    width: 30px;
    height: 30px;
    margin: 10px auto 0;
    background-color: #343536;
    border-radius: 100%;
    box-shadow: inset 0 8px 15px 0 rgba(167, 23, 10, 0.4);
    position: relative;
    text-align: center;
    white-space: nowrap;
  }

  .pin-label {
    text-align: center;
    margin-top: -25px;
    position: relative;
  }
}

