.ssys-home {
  max-width: 375px;
  margin: 0 auto;

  .home-tile {
    background-color: #000;
    color: #fff;
    display: block;

    margin: 2rem;
    padding: 1.5rem;
    text-align: center;

    font-size: 2rem;
    text-transform: uppercase;
  }
}