// must be mapped in Theme.tsx
:root {
  --primary: #090;
  --border: #eee;
  --error: #f00;
  --sidebar: #000;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}